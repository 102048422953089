import {addEventListeners} from '../../../utility/EventListeners/eventListeners';
import  LargeQuery  from '../../../utility/large-query/js/large-query';

export default class searchHistory {
    constructor() {
        this.init();
    }

    private init(): void {
        //save search handler
        addEventListeners('form[name="frmSaveSearch"] .btn.primary','click', (event: Event) => {
            event.preventDefault();
            this.submitForm(event.target);
        });

        //execute query handler
        addEventListeners('.search-history .search-historyLink','click', (event: Event)=> {
            event.preventDefault();
            let target = event.target as HTMLElement;
            while (target && target.tagName !== 'A') {
                target = target.parentElement as HTMLElement;
            }
            if (target && target.tagName === 'A') {
                const url: string = target.getAttribute('href');
                if (url) {
                    // In this case the query is sent un-encoded, we encode it inside the checkIfLargeQuery function
                    const query: string = target.querySelector('.search-historyLink .hidden.query').textContent
                    let isLargeQuery: boolean = LargeQuery.checkIfLargeQuery(query, '/action/doSearch?AllField=', false)
                    LargeQuery.redirect(url, isLargeQuery)
                }
            }
        });
    }

    private submitForm($target: EventTarget): void {
        if ($target instanceof HTMLElement) {
            const form = $target.closest('form') as HTMLFormElement;
            const formData = new FormData(form);
            const alertmeRadio = form.querySelector('input[name="alertme"]:checked') as HTMLInputElement;
            const alertme = alertmeRadio ? alertmeRadio.value : '';
            const saveBtn = document.querySelector('.modal.in .btn.primary') as HTMLElement | null;
            const overlay = $target.closest('.modal__body').querySelector('.saving__overlay') as HTMLElement | null;


            overlay.classList.remove('hidden');
            formData.delete('alertme');
            formData.append('alertme', alertme);

            const xhr = new XMLHttpRequest();
            xhr.open('POST', form.action);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

            xhr.addEventListener('load', () => {
                const closeElement = document.querySelector('.modal.in .close') as HTMLElement | null;
                if (closeElement) {
                    closeElement.click();
                    overlay.classList.add('hidden');
                }
            });

            xhr.send(new URLSearchParams(formData as any).toString());
        }
    }
}
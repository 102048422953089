export default class LargeQuery {
    // Static properties
    public static productUrl: string = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    public static maxGetLength: number = 2048;
    public static maxPostLength: number = 64000;
    public static largeQueryModalBtn: HTMLElement = document.getElementById('large-query-message-btn');

    // Method to check if the input string is too big
    public static checkIfLargeQuery(query: string, searchAction: string, encoded: boolean): boolean {
        if(!encoded) {
            query = encodeURIComponent(query);
        }
        const fullQuery = this.productUrl + searchAction + query;

        // Large query case
        if (fullQuery.length > LargeQuery.maxGetLength) {
            if (fullQuery.length <= LargeQuery.maxPostLength) {
                return true; // Query is large, but within POST limit
            }
            // Query exceeds both GET and POST limits (show error)
            if(this.largeQueryModalBtn) {
                this.largeQueryModalBtn.click();
            }

        } else {
            return false; // Normal query
        }
    }

    // Either redirect normally using GET method if normal query OR construct the form with the hidden fields to redirect using POST method for large queries
    // This function is used in case the clicked link was too lage
    public static redirect(url: string, isLargeQuery: boolean): void {
        if (isLargeQuery === false && url!== "#") {
            window.location.replace(url);
        }
        else {
            const [baseUrl, queryParamsString] = url.split('?', 2);
            const form = document.createElement('form');
            form.method = 'post';
            form.action = baseUrl;

            const queryParams = queryParamsString.split('&');
            for (const param of queryParams) {
                let [key, value] = param.split('=');
                if (key && value !== undefined) {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = decodeURIComponent(key);
                    value = value.replace(/\+/g, ' ');
                    input.value = decodeURIComponent(value);
                    form.appendChild(input);
                }
            }
            document.body.appendChild(form);
            form.submit();
        }
    }

    // Set the value of the method of the form to post if the query is large else keep it as it is.
    // This is only used in the case of form submission
    public static submit(form: HTMLFormElement, isLargeQuery: boolean): void {
        if (isLargeQuery) {
            form.setAttribute('method', 'post');
        }
        form.submit();
    }
}